import React from 'react'
import queryString from 'query-string'

import { injectIntl } from 'react-intl'

import { useLocation, useNavigate } from 'react-router-dom'

import SearchForm from '../components/SearchForm'
import ErrorBoundary from '../components/ErrorBoundary'
import MapLeaflet from '../components/MapLeaflet'
import PlaceList from '../components/PlaceList'
import FetchLocations from '../components/FetchLocations'

class Home extends React.Component {
  state = {
    selected: null,
    opened: null,
  }

  selectPlace = (place) => {
    this.setState({
      selected: place.pk,
    })
  }

  deselectPlace = (place) => {
    if (this.state.selected !== place.pk) {
      return
    }
    this.setState({
      selected: null,
    })
  }

  openPlace = (place) => {
    this.setState({
      opened: place.pk,
    })
  }

  closePlace = () => {
    this.setState({
      opened: null,
    })
  }

  handleLocationFound = (position, values) => {
    console.log(position)
    this.props.navigate(
      '/?' +
        queryString.stringify({
          ...values,
          position: `${position.coords.latitude},${position.coords.longitude}`,
          timestamp: Date.now(),
        })
    )
  }

  render() {
    const {
      franchiseCollection,
      fuelCollection,
      fuelDict,
      location,
      franchiseDict,
      leafletTilesUrl,
    } = this.props
    const searchParams = {
      position: '',
      radius: '',
      franchise: '',
      name: '',
      o: '',
      ...queryString.parse(location.search),
    }

    const MapComponent = MapLeaflet
    //const MapComponent = Map

    return (
      <div className="h-full grid gap-y-4 grid-rows-[auto_minmax(300px,_30vh)_auto] lg:grid-cols-[minmax(450px,_1fr)_2fr] lg:grid-rows-[auto_1fr]">
        <React.Fragment>
          <div className="lg:col-span-2 border-b border-gray-200 shadow-sm">
            <div className="xl:container xl:mx-auto px-4 py-2 sm:px-6 sm:py-4">
              <SearchForm
                onLocationFound={this.handleLocationFound}
                searchParams={searchParams}
                initialValues={searchParams}
                onSearch={(values) => {
                  this.closePlace()
                  this.props.navigate('/?' + queryString.stringify(values))
                }}
                fuelCollection={fuelCollection}
                franchiseCollection={franchiseCollection}
              />
            </div>
          </div>
          <FetchLocations
            searchParams={searchParams}
            render={(places, position, onLoadMore) => (
              <React.Fragment>
                {this.state.selected && this.state.selected.name}
                <div className="lg:row-start-2 lg:col-start-2 lg:self-stretch">
                  <ErrorBoundary>
                    <div className="h-full">
                      <MapComponent
                        leafletTilesUrl={leafletTilesUrl}
                        isMarkerShown
                        places={places}
                        franchiseDict={franchiseDict}
                        selected={this.state.selected}
                        opened={this.state.opened}
                        handleOpen={this.openPlace}
                        handleClose={this.closePlace}
                        position={position}
                        center={{
                          lat: 46.15644,
                          lng: 14.98535,
                        }}
                        zoom={searchParams.lat ? 12 : 9}
                      />
                    </div>
                  </ErrorBoundary>
                </div>
                <div className="lg:overflow-y-auto">
                  {!searchParams.position && (
                    <p className="px-4 search__warning-select-location">
                      Prosimo, vpišite <em>naslov / kraj</em> ali kliknite na{' '}
                      <em>Moja lokacija</em>.
                    </p>
                  )}
                  {searchParams.position && (
                    <React.Fragment>
                      <PlaceList
                        places={places}
                        opened={this.state.opened}
                        handleMouseEnter={this.selectPlace}
                        handleMouseLeave={this.deselectPlace}
                        handleOpen={this.openPlace}
                        handleClose={this.closePlace}
                        fuelCollection={fuelCollection}
                        fuelDict={fuelDict}
                        franchiseDict={franchiseDict}
                        position={position}
                        searchParams={searchParams}
                      />
                      {onLoadMore && !this.state.opened && (
                        <div className="text-center my-4 m-4">
                          <button
                            className="btn w-full btn-secondary"
                            onClick={onLoadMore}
                          >
                            {this.props.intl.formatMessage({
                              id: 'results.load_more',
                            })}
                          </button>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </div>
              </React.Fragment>
            )}
          />
        </React.Fragment>
      </div>
    )
  }
}

const HomeWithLocation = (props) => {
  const location = useLocation()
  const navigate = useNavigate();
  return <Home {...props} location={location} navigate={navigate} />
}

export default injectIntl(HomeWithLocation)
