import React from 'react'
import { FormattedMessage } from 'react-intl'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    console.log(error, info);
  }

  render() {
    //if (this.state.hasError) {
      //return <h1 className="p-4">
          //<FormattedMessage id="app.error" defaultMessage="Something went wrong." />
      //</h1>
    //}
    return this.props.children;
  }
}
