import React from 'react'

import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import markerIcon from 'leaflet/dist/images/marker-icon.png'
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'

import { placesOrPositionChanged, getMarkerIconURL } from '../utils/maps'
import {
  LEAFLET_TILES_URL,
  LEAFLET_TILES_ATTRIBUTION,
  MAP_BOUNDS,
} from '../constants/api'

// see
// https://github.com/PaulLeCam/react-leaflet/issues/967
const DEFAULT_ICON_URL = new L.Icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
  iconAnchor: [12, 41],
  iconSize: [25, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
  tooltipAnchor: [16, -28],
})

class PlaceMarker extends React.Component {
  constructor(props) {
    super(props)
    this.markerRef = React.createRef()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpened !== this.props.isOpened) {
      if (this.props.isOpened) {
        this.markerRef.current.openPopup()
      } else {
        this.markerRef.current.closePopup()
      }
    }
  }

  handleClose = () => {
    this.props.handleClose(this.props.item)
  }

  handleOpen = () => {
    this.props.handleOpen(this.props.item)
  }

  render() {
    const { isOpened, item, selected, franchise } = this.props
    const icon = new L.Icon({
      iconUrl: getMarkerIconURL(
        franchise.marker,
        franchise.marker_hover,
        selected || isOpened
      ),
      iconSize: [31, 41],
      iconAnchor: [15, 41],
      popupAnchor: [0, -41],
    })

    return (
      <Marker
        icon={icon}
        position={[item.lat, item.lng]}
        ref={this.markerRef}
        eventHandlers={{
          popupopen: this.handleOpen,
          popupclose: this.handleClose,
        }}
      >
        <Popup>
          <div className="place">
            <h5 className="place__title">{item.name}</h5>
            <div className="place__description">{item.address}</div>
          </div>
        </Popup>
      </Marker>
    )
  }
}

class MapLeaflet extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
      const map = this.mapRef.current;
    if (map) {
      if (placesOrPositionChanged(prevProps, this.props)) {
        const positions = this.props.places.map((place) => [
          place.lat,
          place.lng,
        ])
        if (this.props.position && this.props.position.lat && this.props.position.lng) {
          positions.push([this.props.position.lat, this.props.position.lng])
        }
        if (positions.length === 1) {
          map.setView(positions[0], 13)
        } else if (positions.length > 1) {
          const bounds = new L.LatLngBounds(positions).pad(0.1)
          map.fitBounds(bounds)
        }
      }
    }
  }

  render() {
    const {
      opened,
      handleOpen,
      handleClose,
      selected,
      zoom,
      center,
      position,
      franchiseDict,
      places,
      leafletTilesUrl,
    } = this.props

    const containerStyle = {
      height: '100%',
    }
    return (
      <MapContainer
        ref={this.mapRef}
        style={containerStyle}
        zoom={zoom}
        minZoom={8}
        center={center}
        maxBounds={MAP_BOUNDS}
      >
        <TileLayer
          attribution={LEAFLET_TILES_ATTRIBUTION}
          url={leafletTilesUrl || LEAFLET_TILES_URL}
        />
        {position && position.lat && position.lng && (
          <Marker
            icon={DEFAULT_ICON_URL}
            position={[position.lat, position.lng]}
          />
        )}

        {places &&
          places.map((place) => (
            <PlaceMarker
              map={this.mapRef.current}
              franchise={franchiseDict[place.franchise]}
              key={place.pk}
              item={place}
              isOpened={opened === place.pk}
              handleOpen={() => handleOpen(place)}
              handleClose={() => handleClose(place)}
              selected={place.pk === selected}
            />
          ))}
      </MapContainer>
    )
  }
}

export default MapLeaflet
